import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_11/4-11-1-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scene1: file(relativePath: { eq: "chapter_4/sub_11/4-11-1-parallax1.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scene2: file(relativePath: { eq: "chapter_4/sub_11/4-11-1-parallax2.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scene3: file(relativePath: { eq: "chapter_4/sub_11/4-11-1-parallax3.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audioImage: file(relativePath: { eq: "chapter_4/sub_11/4-11-1-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer={{ fromLeft: '-20%', size: '80%' }}
          background={{ image: assetQuery.scene1.childImageSharp.fluid }}
          scene={2}
          move={{ x: -10, y: -10 }}
        />
        <Mordo
          scenelayer={{ fromLeft: '-20%', size: '80%' }}
          background={{ image: assetQuery.scene2.childImageSharp.fluid }}
          scene={3}
          move={{ x: -20, y: -20 }}
        />
        <Mordo
          scenelayer={{ fromLeft: '-15%', size: '85%' }}
          background={{ image: assetQuery.scene3.childImageSharp.fluid }}
          scene={1}
          move={{ x: -5, y: -5 }}
        />

        <Mordo
          scenelayer
          scene={3}
          move={{ x: 10, y: 20 }}
        >
          <LeftColContent body={query.leftColumn.body} audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4111malinowski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4111malinowski.mp4`]} audioImage={assetQuery.audioImage.childImageSharp.fluid} />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
