import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import Profile from 'src/components/desktop/profile/profile';
import { SlideXLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_11/4-11-10.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  makuszynski: file(relativePath: {eq: "chapter_4/sub_11/4-11-10-makuszynski.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  broniewski: file(relativePath: {eq: "chapter_4/sub_11/4-11-10-broniewski.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  }
`);
  return (
    <SlideXLarge style={{ padding: 0 }}>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: 0, y: 0 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />

        <Mordo
          scene={1}
          flexi
          move={{ x: -20, y: -10 }}
          customStyle={{ width: '80%', justifyContent: 'center' }}
        >
          <Profile image={assetQuery.broniewski.childImageSharp.fluid} body={query.broniewski.body} />
          <Profile image={assetQuery.makuszynski.childImageSharp.fluid} body={query.makuszynski.body} />
        </Mordo>
      </ParallaxMordo>
    </SlideXLarge>
  );
};

export default Slide;
