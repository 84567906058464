import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import Profile from 'src/components/desktop/profile/profile';
import { SlideXLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_11/4-11-9.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  zeromski: file(relativePath: {eq: "chapter_4/sub_11/4-11-9-zeromski.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  wars: file(relativePath: {eq: "chapter_4/sub_11/4-11-9-wars.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  mackiewicz: file(relativePath: {eq: "chapter_4/sub_11/4-11-9-mackiewicz.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  }
`);
  return (
    <SlideXLarge style={{ padding: 0 }}>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: 0, y: 0 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />

        <Mordo
          scene={1}
          flexi
          move={{ x: -20, y: -10 }}
        >
          <Profile image={assetQuery.zeromski.childImageSharp.fluid} body={query.zeromski.body} />
          <Profile image={assetQuery.wars.childImageSharp.fluid} body={query.wars.body} />
          <Profile image={assetQuery.mackiewicz.childImageSharp.fluid} body={query.mackiewicz.body} />
        </Mordo>
      </ParallaxMordo>
    </SlideXLarge>
  );
};

export default Slide;
