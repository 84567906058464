import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SignedPhoto from 'src/components/desktop/signedPhoto/signedPhoto.jsx';
import { Slide as SlideCustom } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "chapter_4/sub_11/4-11-4.jpg" }) {
      childImageSharp {
        fixed(width: 701, height: 1011) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
      }
    }
  }
`);
  return (
    <SlideCustom width={39}>
      <SignedPhoto
        photo={assetQuery.file.childImageSharp.fixed}
        text={query.mdx.body}
        colorText="#fff"
        widthText={75}
        alt={query.mdx.frontmatter.alt}
      />
    </SlideCustom>
  );
};

export default Slide;
