import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, title, audio = 'Tadeusz Malinowski' }) => (
  <BlockContainer height="1750px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: '11%', marginTop: '1rem' }} body={query.mdx.body} />
      <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4111malinowski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4111malinowski.mp4`]} audioImage={query.audioImageA.childImageSharp.fluid}
        audioDescription={audio} size={2} style={{ margin: '5rem 0' }} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
