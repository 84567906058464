import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { SlideXLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_11/4-11-8.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  parallax: file(relativePath: { eq: "chapter_4/sub_11/4-11-8-parallax.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audioImage: file(relativePath: { eq: "chapter_4/sub_11/4-11-8-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
`);
  return (
    <SlideXLarge style={{ padding: 0 }}>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: 0, y: 0 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          flexi={{ flex: '0 0 90%' }}
          scene={1}
          move={{ x: -20, y: -10 }}
        >
          <LeftColContent body={query.leftColumn.body} style={{ marginLeft: '3rem' }} />

        </Mordo>
        <Mordo
          scene={2}
          move={{ x: 10, y: -20 }}
        >
          <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4118wierzynski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4118wierzynski.mp4`]} audioImage={assetQuery.audioImage.childImageSharp.fluid} size={2} style={{ marginTop: '45vh', width: 'auto', marginRight: '3rem' }} audioDescription={query.audioDesc.excerpt} textStyle={{ width: '350%' }} />
        </Mordo>
      </ParallaxMordo>

    </SlideXLarge>
  );
};

export default Slide;
