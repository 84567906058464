import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, subSlider, audio = 'Kazimierz Wierzyński' }) => (
  <BlockContainer height="2100px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <TextAnimation containerStyle={{ height: '20%', marginTop: '12rem' }} body={query.mdx.body} />
      <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4118wierzynski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4118wierzynski.mp4`]} audioImage={query.audioImageA.childImageSharp.fluid} audioDescription={audio} size={2} />
      {subSlider}
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
